<template>
  <div>
    <div class="modal-body pt-6">
      <p class="h1 my-4 text-center">Bem vindo ao Qevo!</p>

      <div class="row">
        <img
            src="../../../../assets/img/illustrations/happiness.svg"
            height="175"
            alt="..."
        />
      </div>
      <p class="text-center mt-4">Parabéns, você acada de criar sua conta no Qevo 3C</p>

      <p class="text-center">Vamos te ajudar a começar a usar em 3 passos que levem apenas 1 minutinho.</p>

      <div class="col text-center mt-6">
        <button type="button" class="btn btn-primary" @click="next">Continuar</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Welcome",

  methods: {
    next() {
      this.$store.dispatch('welcomeModal/nextStep', 1);
    }
  }
}
</script>

<style scoped>
.btn-primary {
  width: 10rem;
}
</style>