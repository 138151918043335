<template>
  <div>
    <div class="modal-body">
      <div class="col-12">
        <p class="h1 my-4 text-center">Convide quantas pessoas quiser</p>
        <p class="text-center phrase">Quem mais irá utilizar o Qevo em sua empresa?</p>
        <p class="text-center phrase">Informe o e-mail das pessoas da sua equipe para convidá-las:</p>

        <div class="container my-5" style="max-height: 320px; overflow: auto">
          Endereço de e-mail
          <div v-for="(item, index) in emails" v-bind:key="index+item" class="mb-2">
            <input v-model.lazy="emails[index]"
                   class="form-control"
                   :ref="`email${index}`"
                   :class="'emails.'+index in errors || index in errorsInFront ? 'is-invalid' : ''"
                   placeholder="Digite o endereço de e-mail para convite"
                   @focus="newInput(index)">
            <div v-if="'emails.'+index in errors ? 'is-invalid' : ''" class="invalid-feedback">
              <span v-if="'emails.'+index in errors">
                {{ errors[`emails.${index}`][0] }}
              </span>
            </div>
            <div v-if="index in errorsInFront ? 'is-invalid' : ''" class="invalid-feedback">
              Formato de e-mail inválido.
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button v-if="errorsInFront.length === 0" type="button" class="btn btn-primary" @click="next()">Continuar</button>
      <button v-else type="button" class="btn btn-primary" disabled>Continuar</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "InviteEmployees",

  data() {
    return {
      emails: [
        '',
        ''
      ],
      names: [
        '',
        ''
      ],
      errorsInFront: [],
    }
  },

  mounted() {
    this.$refs.email0.focus();
  },

  computed: {
    errors() {
      return this.$store.getters['auth/getInviteUsersErrors'] || {};
    },

    invitedUsersStatus() {
      return this.$store.getters['auth/getInvitedUsersStatus'];
    }
  },

  watch: {
    invitedUsersStatus() {
      if (this.invitedUsersStatus === true) {
        this.$store.dispatch('welcomeModal/nextStep', 3);
      }
    }
  },

  methods: {
    newInput(index) {
      this.errorsInFront = [];

      for (let i = 0; i < this.emails.length; i++) {
        if (this.emails[i] !== '') {
          if (!this.validateEmail(this.emails[i])) {
            this.errorsInFront[i] = true;
          }
        }
      }

      if (this.errorsInFront.length > 0) {
        return;
      }

      if (this.emails.length - 1 < index + 1) {
        this.emails.push('')
        this.names.push('user')
      }
    },

    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    next() {
      this.$store.dispatch('auth/inviteUsers', {
        names: this.names,
        emails: this.emails
      });
    },
  }
}
</script>

<style scoped>
.phrase {
  margin-bottom: 2px !important;
}
</style>