<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <Welcome v-if="step === 0"></Welcome>
            <UpdateUser v-if="step === 1"></UpdateUser>
            <InviteEmployees v-if="step === 2"></InviteEmployees>
            <Finish v-if="step === 3"></Finish>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Welcome from "./steps/Welcome";
import UpdateUser from "./steps/UpdateUser";
import InviteEmployees from "./steps/InviteEmployees";
import Finish from "./steps/Finish";

export default {
  components: {Finish, InviteEmployees, UpdateUser, Welcome},

  computed: {
    step() {
      return this.$store.getters['welcomeModal/getWelcomeModalStep'];
    }
  },

  mounted() {
    this.$store.dispatch('welcomeModal/nextStep', 0);
  }
}
</script>

<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-body {
  height: 560px !important;
}

</style>