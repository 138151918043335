<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <UpdateUser :display-name="false"></UpdateUser>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import UpdateUser from "./firstLoginModalFlow/steps/UpdateUser";

export default {
  components: {UpdateUser},

  computed: {
    updatedStatus() {
      return this.$store.getters['auth/getUpdatedStatus'];
    }
  },

  watch: {
    updatedStatus() {
      if (this.updatedStatus === true) {
        this.$store.dispatch('welcomeModal/closeResetPasswordModal')
      }
    }
  },
}
</script>

<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-body {
  height: 560px !important;
}

</style>
