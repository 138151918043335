<template>
  <div id="calls">
    <table class="table table-sm">
      <thead>
      <tr>
        <th scope="col">Usuário</th>
        <th scope="col">Número</th>
        <th scope="col">Data e hora</th>
        <th scope="col">Duração</th>
        <th scope="col">Status</th>
        <th scope="col">Qualificação</th>
      </tr>
      </thead>
      <tbody v-if="!noCalls">
      <tr v-for="call in calls" :key="call.id">
        <td>{{ call.agent }}</td>
        <td>{{ formatter.phoneNumber(call.number) }}</td>
        <td>{{ formatter.datetimePtBr(call.call_date) }}</td>
        <td>{{ call.speaking_time }}</td>
        <td>{{ call.readable_status_text }}</td>
        <td>{{ call.qualification }}</td>
      </tr>
      </tbody>
    </table>
    <div v-if="noCalls" class="d-flex justify-content-center text-muted">
      <h5>Nenhuma ligação hoje</h5>
    </div>
  </div>
</template>

<script>
import formatter from '../../util/formatter'

export default {
  name: "CallTable",

  data() {
    return {
      formatter: formatter
    }
  },

  computed: {
    calls() {
      return this.$store.getters['dashboard/getLastCalls'] || [];
    },
    noCalls(){
      return this.calls.length === 0;
    }
  },

  mounted() {
    this.$store.dispatch('dashboard/fetchLastCalls');
  }
}
</script>

<style scoped>
#calls {
  max-height: 380px;
  overflow: auto;
  width: 100% !important;
}
</style>
