<template>
  <div class="card">
    <div class="card-header">
      <h4 class="card-header-title">
        <span v-if="chart === 1">
          Realizadas e atendidas por usuário
        </span>
        <span v-else>
          Tempo de uso do telefone
        </span>
      </h4>
      <ul class="nav nav-tabs nav-tabs-sm card-header-tabs">
        <li class="nav-item">
          <a class="nav-link" :class="chart === 1 ? 'active' : ''" @click="changeChart(1)">
            Quantidade
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" :class="chart === 2 ? 'active' : ''" @click="changeChart(2)">
            Tempo
          </a>
        </li>
      </ul>
    </div>
    <div class="card-body">
      <div :style="chart === 1 ? '' : 'display: none;'">
        <div class="chart chart-appended">
          <div id="chart">
            <apexchart type="bar" height="350px" :options="quantityChartData.options"
                       :series="quantityChartData.series"></apexchart>
          </div>
        </div>
      </div>
      <div :style="chart === 2 ? '' : 'display: none;'">
        <apexchart type="bar" height="350px" :options="timeChartData.options"
                   :series="timeChartData.series"></apexchart>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AgentsCallsChart",
  data() {
    return {
      chart: 1,
      commonOptions: {
        noData: {
          text: 'Nenhuma ligação hoje',
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: "#95aac9",
            fontSize: '14px',
            fontFamily: 'Cerebri Sans',
          }
        },
        style: {
          fontFamily: 'Cerebri Sans',
        },
        states: {
          hover: {
            filter: {
              type: 'none',
            }
          },
        },
        chart: {
          type: 'bar',
          height: 350,
          stacked: true,
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '30%',
          },
        },
        stroke: {
          width: 1,
          colors: ['#ffffff'],
        },
        fill: {
          opacity: 3,
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
          offsetX: 40
        }
      }
    }
  },

  mounted() {
    this.$store.dispatch('dashboard/fetchAgentsMetrics');
  },

  computed: {
    metrics() {
      return this.$store.getters['dashboard/getAgentsMetrics'];
    },

    quantityChartData() {
      let metric = this.$store.getters['dashboard/getAgentsMetrics'];
      let labels = [];
      let answeredCalls = [];
      let madeCalls = [];

      for (let i = 0; i < metric.length; i++) {
        labels.push(metric[i].agent.name ?? 'Agente');
        madeCalls.push(metric[i].manual_calls_made);
        answeredCalls.push(metric[i].manual_calls_answered);
      }

      return {
        series: [{
          name: 'Atendidas',
          data: madeCalls,
          color: '#4687e2',
        }, {
          name: 'Não completadas',
          data: answeredCalls,
          color: '#c8daf6',
        },],
        options: Object.assign({}, this.commonOptions, {xaxis: {categories: labels}}
        )
      }
    },

    timeChartData() {
      let metric = this.$store.getters['dashboard/getAgentsMetrics'];
      let labels = [];
      let callsSpeaking = [];
      let callsCalling = [];

      for (let i = 0; i < metric.length; i++) {
        labels.push(metric[i].agent.name ?? 'Agente');
        callsSpeaking.push(metric[i].manual_calls_speaking);
        callsCalling.push(metric[i].manual_calls_calling);
      }

      return {
        series: [{
          name: 'Falando',
          data: callsSpeaking,
          color: '#4687e2',
        }, {
          name: 'Aguardando atendimento',
          data: callsCalling,
          color: '#c8daf6',
        }],

        options: Object.assign(
            {},
            this.commonOptions,
            {
              yaxis: {},
              xaxis: {
                categories: labels,
                labels: {
                  formatter: (value) => {
                    var h = Math.floor(value / 60);
                    var m = value % 60;

                    return (h > 9 ? h : '0' + h) + ':' + (m > 9 ? m : '0' + m) + ':00';
                  },
                }
              },
              dataLabels: {
                formatter: function (value) {
                  var h = Math.floor(value / 60);
                  var m = value % 60;
                  return (h > 9 ? h : '0' + h) + ':' + (m > 9 ? m : '0' + m) + ':00'
                },
              }
            }
        )
      }
    }
  },

  methods: {
    changeChart(option) {
      this.chart = option;
    },
  }
}

</script>

<style scoped>
.card-body {
  height: 385px !important;
}

.nav-link{
  cursor: pointer !important;
}
</style>
