<template>
  <div>
    <div class="modal-body ">
      <h1 class="text-center">Atualize seus dados</h1>
      <p v-if="displayName" class="ext-muted text-center mb-5">Defina a sua senha de acesso para poder fazer o login no
        sistema.</p>

      <div class="form-group" v-if="displayName">
        <!-- Label -->
        <label class="form-label"> Seu nome </label>

        <!-- Input -->
        <input
            v-model="name"
            type="text"
            class="form-control"
            placeholder="Digite seu nome"
            :class="Array.isArray(updateUserErrors?.name) ? 'is-invalid' : ''"
        />
        <div v-if="Array.isArray(updateUserErrors?.name)" class="invalid-feedback">
                  <span v-for="error in updateUserErrors?.name" :key="error">
                    {{ error }}
                  </span>
        </div>
      </div>

      <div class="form-group">
        <!-- Label -->
        <label class="form-label"> Senha </label>

        <!-- Input -->
        <input
            v-model="password"
            type="password"
            class="form-control"
            placeholder="Digite sua senha"
            :class="Array.isArray(updateUserErrors?.password) ? 'is-invalid' : ''"
        />
        <div v-if="Array.isArray(updateUserErrors?.password)" class="invalid-feedback">
                  <span v-for="error in updateUserErrors?.password" :key="error">
                    {{ error }}
                  </span>
        </div>
      </div>

      <div class="form-group">
        <!-- Label -->
        <label class="form-label"> Informe-a novamente para confirmar </label>

        <!-- Input -->
        <input
            v-model="confirmPassword"
            type="password"
            class="form-control"
            placeholder="Confirme sua senha"
            :class="Array.isArray(updateUserErrors?.password_confirmation) ? 'is-invalid' : ''"
        />
        <div v-if="Array.isArray(updateUserErrors?.password_confirmation)" class="invalid-feedback">
                  <span v-for="error in updateUserErrors?.password_confirmation" :key="error">
                    {{ error }}
                  </span>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" @click="updatePassword()">Continuar</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UpdateUser',

  props: {
    displayName: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  data() {
    return {
      name: '',
      password: '',
      confirmPassword: '',
    }
  },

  computed: {
    updateUserErrors() {
      return this.$store.getters['auth/getUpdateNameAndPasswordErrors'];
    },

    updatedStatus() {
      return this.$store.getters['auth/getUpdatedStatus'];
    },

    user() {
      return this.$store.getters['auth/user'];
    }
  },

  watch: {
    updatedStatus() {
      if (this.updatedStatus === true) {
        this.$store.dispatch('welcomeModal/nextStep', 2)
      }
    }
  },

  methods: {
    updatePassword() {
      let user_name = this.user.name
      if (this.displayName) {
        user_name = this.name
      }
      this.$store.dispatch('auth/updateUser', {
        name: user_name,
        password: this.password,
        user_id: this.user.id,
        password_confirmation: this.confirmPassword
      })
    }
  }
}
</script>

<style scoped>

</style>
