<template>
  <layout header-pre-title="overview" header-title="Dashboard do dia">
    <template v-slot:content>
      <metrics></metrics>

      <div class="row">
        <div class="col-12 col-xl-8">
          <agents-calls-chart></agents-calls-chart>
        </div>
        <div class="col-12 col-xl-4">
          <div class="card">
            <div class="card-header">
              <h4 class="card-header-title">
                Qualificações
              </h4>
            </div>
            <div class="card-body">
              <qualification-donut-chart v-if="qualifications?.length > 0" :qualifications="qualifications"></qualification-donut-chart>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-lg-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-header-title">
                Últimas 10 ligações
              </h4>
            </div>
            <call-table class="col-7"></call-table>
          </div>
        </div>
      </div>

      <div v-if="welcomeModal">
        <StaticModal></StaticModal>
      </div>
      <div v-if="resetPasswordModal">
        <ResetPasswordModal></ResetPasswordModal>
      </div>
    </template>
  </layout>
</template>

<script>
import Layout from "../components/layout/Layout";
import StaticModal from "../components/auth/firstLoginModalFlow/StaticModal";
import Metrics from "../components/dashboard/Metrics";
import CallTable from "../components/dashboard/CallTable";
import QualificationDonutChart from "../components/common/QualificationDonutChart";
import AgentsCallsChart from "../components/dashboard/AgentsCallsChart";
import moment from "moment";
import ResetPasswordModal from "../components/auth/ResetPasswordModal";

export default {
  name: "HelloWorld",
  components: {
    ResetPasswordModal,
    AgentsCallsChart,
    QualificationDonutChart,
    CallTable,
    Metrics,
    Layout,
    StaticModal
  },

  data() {
    return {
      updateNameAndPassword: false,
    }
  },

  computed: {
    qualifications() {
      return this.$store.getters['dashboard/getQualifications'];
    },
    welcomeModal() {
      return this.$store.getters['welcomeModal/getStartModal'];
    },
    resetPasswordModal() {
      return this.$store.getters['welcomeModal/getResetPasswordModal'];
    }
  },

  mounted() {
    this.$store.dispatch('dashboard/fetchQualifications', {
      dates: {
        start_date_to_qualification: moment().format('yyyy-MM-DD'),
        end_date_to_qualification: moment().format('yyyy-MM-DD')
      }
    });
  },

  created() {
    this.$store.dispatch('auth/fetchUserCampaign');
  }
};
</script>

<style scoped>
.card-body {
  height: 385px !important;
}
</style>
