<template>
  <div>
    <div class="modal-body vertical-center">
      <div class="col-12 py-2">
        <p class="h1 my-4 text-center">Tudo Pronto</p>

        <div class="row">
          <img
              src="../../../../assets/img/illustrations/happiness.svg"
              height="175"
              alt="..."
          />
        </div>
        <p class="text-center mt-4">As pessoas convidadas receberam um e-mail com um link para acessarem a sua conta no
         Qevo</p>

        <p class="text-center">Você já pode começar a realizar ligações!</p>
      </div>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-primary" @click="finish">Começar a usar</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Finish",

  methods: {
    finish() {
      this.$store.dispatch('welcomeModal/startModal', false);
    },
  }
}
</script>

<style scoped>
.vertical-center {
  min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
  display: flex;
  align-items: center;
}
</style>
